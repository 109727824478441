import { Routes } from '@angular/router';
import { AuthGuard } from '@shared/guards/auth.guard';
import { MaintenanceGuard } from '@shared/guards/maintenance.guard';
import { RoutePaths } from './app.utils';
import { MaintenanceComponent } from './maintenance/maintenance.component';

export const routes: Routes = [
  {
    path: 'platform',
    canActivate: [AuthGuard, MaintenanceGuard],
    canActivateChild: [AuthGuard, MaintenanceGuard],
    loadChildren: () => import('./platform/platform.routes').then((r) => r.platformRoutes),
    data: { preload: true },
  },
  {
    path: ':type',
    outlet: 'modal',
    canActivate: [AuthGuard, MaintenanceGuard],
    canActivateChild: [AuthGuard, MaintenanceGuard],
    loadChildren: () => import('./platform/platform.routes').then((r) => r.platformRoutes),
  },
  {
    path: 'landing',
    redirectTo: 'landing/login',
  },
  {
    path: 'landing',
    loadChildren: () => import('./landing/landing.routes').then((r) => r.landingRoutes),
  },
  {
    path: RoutePaths.Maintenance,
    canActivate: [MaintenanceGuard],
    component: MaintenanceComponent,
    data: { title: 'Maintenance' },
  },
  {
    path: '',
    redirectTo: 'platform',
    pathMatch: 'full',
  },
  {
    path: '**',
    redirectTo: 'platform',
  },
];
