import { FormControl, FormGroup } from '@angular/forms';
import { ENUM_AdvisorUserPermissionLevel } from '@api-new/common';
import {
  HTTP_AP_ListProperties_ForTable_Request_Filter,
  HTTP_AP_ListProperties_ForTable_Request_Sort,
  HTTP_AP_ListProperties_ForTable_Response_Property,
} from '@api-new/propertyservice';
import { ProtoFormModel } from '@shared-lib/models/proto-form.model';
import { RowDefinitionType, TableRowDefinitionModel } from '@shared/components/table';
import { Permission } from '@shared/enums';
import { HasCurrentUserRequiredPermissionsFunction } from '@shared/services/permission.service';

export const getPropertyRowsDefinition = (
  hasCurrentUserRequiredPermissions: HasCurrentUserRequiredPermissionsFunction,
  isPlatformTypeLenderPortal: boolean,
): TableRowDefinitionModel<HTTP_AP_ListProperties_ForTable_Response_Property, HTTP_AP_ListProperties_ForTable_Request_Sort>[] => [
  {
    columnLabel: 'Address',
    type: RowDefinitionType.ADDRESS,
    columnKey: 'propertyAddressLines',
    sortKey: 'byAddress',
    width: {
      min: '240px',
      max: '240px',
    },
    isFrozen: true,
  },
  {
    columnLabel: 'Status',
    type: RowDefinitionType.TEXT,
    columnKey: 'latestZooplaPropertyListingStatus',
    width: {
      min: '140px',
      max: '140px',
    },
  },
  {
    columnLabel: 'Status Update',
    type: RowDefinitionType.DATE_TIME_SINCE,
    columnKey: 'latestZooplaPropertyListingStatusListedDatetime',
    sortKey: 'byLatestZooplaPropertyListingStatusListedDatetime',
    width: {
      min: '120px',
      max: '120px',
    },
  },
  {
    columnLabel: 'Investment type',
    type: RowDefinitionType.PROPERTY_INVESTMENT_TYPE,
    columnKey: 'propertyInvestmentType',
    width: {
      min: '140px',
      max: '140px',
    },
  },
  {
    columnLabel: 'Client name',
    type: RowDefinitionType.TEXT,
    columnKey: 'clientUserFullName',
    sortKey: 'byClientName',
    width: {
      min: '200px',
      max: '200px',
    },
  },
  {
    columnLabel: 'Account number',
    type: RowDefinitionType.TEXT,
    isHidden: !isPlatformTypeLenderPortal,
    columnKey: 'mortgagePartAccountReferenceNumbers',
    width: {
      min: '120px',
      max: '120px',
    },
  },
  {
    columnLabel: 'Company',
    type: RowDefinitionType.TEXT,
    columnKey: 'companyTradingName',
    sortKey: 'byCompanyName',
    isHidden: !hasCurrentUserRequiredPermissions([
      {
        permission: Permission.CLIENT_USER_READ,
        permissionLevel: ENUM_AdvisorUserPermissionLevel.ADVISOR_USER_PERMISSION_LEVEL_COMPANY,
      },
    ]),
    width: {
      min: '200px',
      max: '200px',
    },
  },
  {
    columnLabel: 'Equity',
    type: RowDefinitionType.CURRENCY,
    columnKey: 'currentEquityValue',
    width: {
      min: '120px',
      max: '120px',
    },
  },
  {
    columnLabel: 'Equity (%)',
    type: RowDefinitionType.PERCENT,
    columnKey: 'currentEquityPercentage',
    width: {
      min: '120px',
      max: '120px',
    },
  },
  {
    columnLabel: 'Value',
    type: RowDefinitionType.CURRENCY,
    columnKey: 'propertyCurrentPropertyValue',
    width: {
      min: '120px',
      max: '120px',
    },
  },
];

export type PropertyTableFilterFormModel = ProtoFormModel<HTTP_AP_ListProperties_ForTable_Request_Filter>;

export const propertyTableFilterDefaults: HTTP_AP_ListProperties_ForTable_Request_Filter = {
  propertyAddress: '',
  clientUserFullNameOrEmail: '',
  latestZooplaPropertyListingStatuses: [],
  companyIds: null,
  mortgagePartAccountReferenceNumber: '',
  propertyInvestmentType: null,
};

export const buildPropertyTableFilterForm = (): FormGroup<PropertyTableFilterFormModel> =>
  new FormGroup<PropertyTableFilterFormModel>({
    propertyAddress: new FormControl(''),
    clientUserFullNameOrEmail: new FormControl(''),
    latestZooplaPropertyListingStatuses: new FormControl([]),
    companyIds: new FormControl(null),
    mortgagePartAccountReferenceNumber: new FormControl(''),
    propertyInvestmentType: new FormControl(null),
  });
