import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthApiService } from '@api-new/authservice';
import { RoutePaths } from '@app/app.utils';
import { SignInApiErrorCodeMap } from '@app/landing/maps/SignInApiErrorCodeMap';
import { getUserIdFromToken } from '@app/shared/utils/tokenUtils';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { ToastService, ToastSeverity } from '@shared-lib/services/toast.service';
import { SignInApiErrorCode } from '@shared/enums';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { ErrorModel } from '../../models/app-state.model';
import { AuthService } from '../../services/auth.service';
import { getUserById } from '../user/user.action';
import {
  authError,
  loginAction,
  loginFailureAction,
  loginSuccessAction,
  loginWithTokenAction,
  loginWithTokenFromSSOAction,
  logoutAction,
} from './auth.action';

@Injectable()
export class AuthEffect {
  login = createEffect(() => {
    return this.actions$.pipe(
      ofType(loginAction),
      tap(() => {
        this.authService.clearRedirectUrl();
      }),
      switchMap(({ email, password }) =>
        this.authApiService
          .HTTP_AP_SignIn_WithPassword({
            email,
            password,
          })
          .pipe(
            switchMap(({ customToken }: { customToken: string }) => {
              const userId = getUserIdFromToken(customToken);
              return of(loginSuccessAction({ token: customToken, uid: userId }));
            }),
            catchError((error: ErrorModel) => {
              return of(loginFailureAction({ error }));
            }),
          ),
      ),
    );
  });

  loginWithToken = createEffect(() => {
    return this.actions$.pipe(
      ofType(loginWithTokenAction),
      tap(() => {
        this.authService.clearRedirectUrl();
      }),
      switchMap(({ customToken }: { customToken: string }) => {
        const userId = getUserIdFromToken(customToken);
        return of(loginSuccessAction({ token: customToken, uid: userId }));
      }),
      catchError((error: ErrorModel) => {
        return of(loginFailureAction({ error }));
      }),
    );
  });

  loginWithTokenFromSSO = createEffect(() => {
    return this.actions$.pipe(
      ofType(loginWithTokenFromSSOAction),
      switchMap(({ customToken }: { customToken: string }) => {
        const userId = getUserIdFromToken(customToken);
        return of(loginSuccessAction({ token: customToken, uid: userId }));
      }),
      catchError((error: ErrorModel) => {
        return of(loginFailureAction({ error }));
      }),
    );
  });

  logout = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(logoutAction),
        tap(() => {
          this.authService.logout();
          this.router.navigate([RoutePaths.Landing, RoutePaths.Login]);
        }),
      );
    },
    { dispatch: false },
  );

  loginSuccess = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(loginSuccessAction),
        tap(({ token, uid }) => {
          this.store.dispatch(getUserById({ id: uid }));
          this.authService.setAccessToken(token);
          const redirectUrl = this.authService.getRedirectUrl();
          if (redirectUrl) {
            void this.router.navigate([redirectUrl]);
            this.authService.clearRedirectUrl();
            return;
          }
          void this.router.navigate([RoutePaths.Platform, RoutePaths.OpportunitiesApprovalRequired]);
        }),
      );
    },
    { dispatch: false },
  );

  loginFailure$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(loginFailureAction),
      map(({ error }: { error: ErrorModel }) => {
        let errorMessage = error?.error?.message;
        let authErrorCode: SignInApiErrorCode = null;
        if (SignInApiErrorCodeMap.has(errorMessage)) {
          authErrorCode = error.error.message as SignInApiErrorCode;
          switch (authErrorCode) {
            case SignInApiErrorCode.ADVISOR_SIGN_IN__CREDENTIALS_INVALID: {
              errorMessage = SignInApiErrorCodeMap.get(SignInApiErrorCode.ADVISOR_SIGN_IN__CREDENTIALS_INVALID);
              break;
            }
            case SignInApiErrorCode.ADVISOR_SIGN_IN__EMAIL_NOT_FOUND: {
              return authError({ authErrorCode });
            }
          }
        }
        this.toastService.showToast(ToastSeverity.error, errorMessage, { summary: 'Login error' });
        return authError({ authErrorCode });
      }),
    );
  });

  constructor(
    private readonly actions$: Actions,
    private readonly authService: AuthService,
    private readonly authApiService: AuthApiService,
    private readonly router: Router,
    private readonly toastService: ToastService,
    private readonly store: Store,
  ) {}
}
