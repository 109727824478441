import { ENUM_AdvisorUserPermissionLevel } from '@api-new/common';
import { HTTP_AP_ListAdvisorUsers_ForTable_Request_Filter } from '@api-new/userservice';
import { UserSortKey, UserTableRow } from '@platform/user/models/user-table.model';
import { RowDefinitionType, TableRowDefinitionModel } from '@shared/components/table';
import { Permission } from '@shared/enums';
import { HasCurrentUserRequiredPermissionsFunction } from '@shared/services/permission.service';

export const getUserRowsDefinition = (
  hasCurrentUserRequiredPermissions: HasCurrentUserRequiredPermissionsFunction,
): TableRowDefinitionModel<UserTableRow>[] => {
  return [
    {
      columnLabel: 'Name',
      sortKey: UserSortKey.BY_FULL_NAME,
      type: RowDefinitionType.TEXT,
      columnKey: 'fullName',
      width: {
        min: '240px',
        max: '240px',
      },
      isFrozen: true,
    },
    {
      columnLabel: 'Last login',
      sortKey: UserSortKey.BY_LAST_SIGN_IN,
      type: RowDefinitionType.DATE_TIME_SINCE,
      columnKey: 'lastSignIn',
      width: {
        min: '120px',
        max: '120px',
      },
    },
    {
      columnLabel: 'Company',
      sortKey: UserSortKey.BY_COMPANY,
      type: RowDefinitionType.TEXT,
      columnKey: 'companyName',
      width: {
        min: '200px',
        max: '200px',
      },
      isHidden: !hasCurrentUserRequiredPermissions([
        {
          permission: Permission.ADVISOR_USER_READ,
          permissionLevel: ENUM_AdvisorUserPermissionLevel.ADVISOR_USER_PERMISSION_LEVEL_COMPANY,
        },
      ]),
    },
    {
      columnLabel: 'Email',
      sortKey: UserSortKey.BY_EMAIL,
      type: RowDefinitionType.TEXT,
      columnKey: 'email',
      width: {
        min: '240px',
        max: '240px',
      },
    },
    {
      columnLabel: 'Clients',
      type: RowDefinitionType.NUMBER,
      columnKey: 'clientCount',
      width: {
        min: '70px',
        max: '70px',
      },
    },
  ];
};

export const userTableFilterDefaults: Partial<HTTP_AP_ListAdvisorUsers_ForTable_Request_Filter> = {
  fullNameOrEmail: null,
  companyIds: null,
};
