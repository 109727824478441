@if (externalLink == null) {
  <a
    class="link"
    [ngClass]="textClass"
    [routerLink]="routerLink"
    [queryParams]="queryParams"
    (click)="$event.stopPropagation()">
    <ng-container *ngTemplateOutlet="content" />
  </a>
} @else {
  <a
    class="link"
    [ngClass]="textClass"
    [href]="externalLink | standardizeUrlForHref"
    [target]="'_blank'">
    <ng-container *ngTemplateOutlet="content" />
  </a>
}

<ng-template #content>
  <span #linkText>
    <ng-content />
  </span>
  @if (!linkText.hasChildNodes()) {
    <span>{{ label }}</span>
  }
  @if (showIcon) {
    @if (externalLink) {
      <shared-icon class="link-icon external-link-icon" icon="external-link" />
    } @else {
      <shared-icon class="link-icon" icon="arrow-right" />
    }
  }
</ng-template>
