import { CommonModule, CurrencyPipe, PercentPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, ContentChild, EventEmitter, Input, Output, inject } from '@angular/core';
import { BinaryStatusComponent } from '@app/shared/components/binary-status/binary-status.component';
import { LinkTextSize } from '@app/shared/components/link/link.component';
import { NotificationSettingsTagComponent, TagColor } from '@app/shared/components/tag/notification-settings-tag.component';
import { ENUM_PropertyInvestmentTypeRecord } from '@app/shared/maps';
import { BooleanToYesOrNoPipe } from '@app/shared/pipes/boolean-to-yes-or-no/boolean-to-yes-or-no.pipe';
import { DateFormatPipe } from '@app/shared/pipes/date-format/date-format.pipe';
import { NameFormatPipe } from '@app/shared/pipes/name-format.pipe';
import { TOAST_MESSAGE } from '@app/shared/utils';
import { IconComponent } from '@shared-lib/components/icon/icon.component';
import { JoinPipe } from '@shared-lib/pipes/join.pipe';
import { ToastAction, ToastService, ToastSeverity } from '@shared-lib/services/toast.service';
import { FormattedDurationMonthsPipe } from '@shared/pipes/formatted-duration-months';
import { TableModule } from 'primeng/table';
import { TooltipModule } from 'primeng/tooltip';
import { RowDefinitionType } from '../../enums/RowDefinitionType.enum';

@Component({
  selector: 'ap-table-cell',
  imports: [
    CommonModule,
    DateFormatPipe,
    BooleanToYesOrNoPipe,
    NameFormatPipe,
    CurrencyPipe,
    PercentPipe,
    TooltipModule,
    IconComponent,
    BinaryStatusComponent,
    JoinPipe,
    TableModule,
    NotificationSettingsTagComponent,
    FormattedDurationMonthsPipe,
  ],
  templateUrl: './table-cell.component.html',
  styleUrl: './table-cell.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableCellComponent {
  @ContentChild('customCellContent') customCellContent!: any;
  readonly toastService = inject(ToastService);
  @Input({ required: true }) row;
  @Input({ required: true }) rowDefinition;

  protected readonly ROW_DEFINITION_TYPE = RowDefinitionType;
  protected readonly ENUM_PropertyInvestmentTypeRecord = ENUM_PropertyInvestmentTypeRecord;
  protected readonly LINK_TEXT_SIZE = LinkTextSize;
  protected readonly TagColor = TagColor;

  copyLink(event: MouseEvent, link: string): void {
    event.stopPropagation();
    navigator.clipboard
      .writeText(link)
      .then(() => this.toastService.showToast(ToastSeverity.success, TOAST_MESSAGE.SUCCESS[ToastAction.action_copy]('Link')))
      .catch(() => this.toastService.showToast(ToastSeverity.error, TOAST_MESSAGE.ERROR[ToastAction.action_copy]('Link')));
  }
}
