<div class="table-header">
  @if (selectedRows?.length > 0 && bulkActionsEnabled) {
    <div class="bulk-actions">
      {{ selectedRows.length }}
      <span>selected:</span>
      <div class="bulk-actions-button" (click)="menu.toggle($event)">
        <shared-icon icon="bulk-actions" size="large" /> Bulk actions
      </div>
      <div class="unselect-all" (click)="emitUnselectAllRows()">Unselect all</div>
      <ng-content select="bulkActionsMenu" />
      <p-menu
        #menu
        [popup]="true"
        [model]="bulkActionMenuItems"
        [style]="{ width: '130px' }"
        appendTo="body" />
    </div>
  }
  @if (pagination && !errors) {
    <div class="entries-number">
      {{ (pagination?.totalCount | number) ?? "-" }} {{ countLabel }}
    </div>
  }
</div>

@if (errors) {
  <ap-table-error (retryButtonClicked)="emitFetchData()" />
} @else {
  <p-table
    #table
    responsiveLayout="stack"
    class="table-wrapper"
    [class.horizontally-scrollable]="isHorizontallyScrollable"
    [class.vertically-scrollable]="isVerticallyScrollable"
    [value]="loading ? null : rowData"
    [dataKey]="dataKey"
    [selection]="selectedRows"
    (selectionChange)="emitRowsSelected($event)"
    [selectionPageOnly]="true"
    [sortField]="latestSort?.sortKey"
    [sortOrder]="latestSort?.sortOrder | tableSortOrderToNumber"
    (sortFunction)="emitSort($event)"
    [customSort]="true"
    [tableStyle]="{ width: '100%', tableLayout: 'fixed' }"
    [scrollable]="true"
    scrollHeight="100%">
    <ng-template pTemplate="header">
      <tr #tableHeader>
        @if (bulkActionsEnabled) {
          <th class="checkbox" pFrozenColumn [frozen]="true">
            <p-tableHeaderCheckbox />
          </th>
        }
        @for (rowDefinition of rowDefinitions; track rowDefinition) {
          @if (!rowDefinition.isHidden) {
            <th
              [class]="rowDefinition.type"
              [style.min-width]="rowDefinition.width?.min"
              [style.max-width]="rowDefinition.width?.max"
              class="table-header-column"
              [pSortableColumn]="rowDefinition.sortKey"
              pFrozenColumn
              [frozen]="rowDefinition.isFrozen">
              @if (!!rowDefinition.sortKey) {
                <div class="sortable-column" [ngStyle]="rowDefinition.headerStyle">
                  {{ rowDefinition.columnLabel }}
                  <ap-sort-icon [sort]="latestSort" [field]="rowDefinition.sortKey" />
                </div>
              } @else {
                <div [ngStyle]="rowDefinition.headerStyle">
                  {{ rowDefinition.columnLabel }}
                </div>
              }
            </th>
          }
        }
        @if (rowActionsEnabled || customRowActions) {
          <th class="table-header-column actions"></th>
        }
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-row>
      <tr
        [pSelectableRow]="row"
        [tabIndex]="rowData.indexOf(row)"
        (mouseenter)="onHoverEnterRow(row)"
        (mouseleave)="onHoverLeaveRow()"
        (click)="emitRowClicked($event, row)"
        [class.hover]="row === hoverOverRow">
        @if (hasCustomRow) {
          @if (bulkActionsEnabled) {
            <td class="checkbox" pFrozenColumn [frozen]="true">
              <p-tableCheckbox [value]="row" (click)="$event.stopPropagation()" />
            </td>
          }
          <ng-template
            [ngTemplateOutlet]="customRowRef"
            [ngTemplateOutletContext]="{ $implicit: row }" />
        } @else {
          @if (bulkActionsEnabled) {
            <td class="checkbox" pFrozenColumn [frozen]="true">
              <p-tableCheckbox [value]="row" (click)="$event.stopPropagation()" />
            </td>
          }
          @for (rowDefinition of rowDefinitions; track rowDefinition) {
            @if (!rowDefinition.isHidden) {
              <ng-template
                [ngTemplateOutlet]="cellTemplate"
                [ngTemplateOutletContext]="{ row, rowDefinition }" />
            }
          }
          @if ((bulkActionsEnabled || rowActionsEnabled) && row.hasActionInProgress) {
            <td
              [style]="{ padding: '12px' }"
              class="row-loading-spinner"
              pFrozenColumn
              [frozen]="true"
              alignFrozen="right">
              <p-progressSpinner styleClass="table-row-progress-spinner" />
            </td>
          }
        }
        @if (rowActionsEnabled) {
          @if (!row.hasActionInProgress) {
            <td
              (click)="showMenu(row, $event)"
              pFrozenColumn
              [frozen]="true"
              alignFrozen="right"
              class="actions">
              <div class="menu-button">
                <shared-icon icon="menu-dots" class="action-menu-button" />
              </div>
            </td>
          }
        }
      </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage">
      @if (loading) {
        <tr class="table-skeleton" *ngRepeat="pagination?.pageSize ?? 10">
          <td
            [attr.colspan]="
              rowDefinitions.length +
              (bulkActionsEnabled ? 1 : 0) +
              (customRowActions || rowActionMenuItems?.length ? 1 : 0)
            ">
            <p-skeleton height="50px" width="100%" />
          </td>
        </tr>
      }
      @if (!loading && !errors) {
        <tr class="table-empty-message">
          <td
            [attr.colspan]="
              rowDefinitions.length +
              (bulkActionsEnabled && 1) +
              (customRowActions || rowActionMenuItems?.length ? 1 : 0)
            ">
            <span class="ap-label">{{ emptyMessage }}</span>
          </td>
        </tr>
      }
    </ng-template>
  </p-table>
  @if (rowData?.length && pagination) {
    <ap-paginator
      [numberOfPages]="pagination.totalPages"
      [selectedPage]="pagination.pageNumber"
      [pageSize]="pagination.pageSize"
      [numberOfEntries]="pagination.totalCount"
      (onPage)="emitPaginate($event)" />
  }
}

<p-menu
  #actionMenu
  [popup]="true"
  [style]="{ marginTop: '-34px' }"
  [model]="rowActionMenuItems"
  appendTo="body" />

<ng-template #cellTemplate let-row="row" let-rowDefinition="rowDefinition">
  <td
    [class]="rowDefinition.type"
    [ngStyle]="rowDefinition.style"
    pFrozenColumn
    [frozen]="rowDefinition.isFrozen"
    [style.min-width]="rowDefinition.width?.min"
    [style.max-width]="rowDefinition.width?.max">
    <ap-table-cell [row]="row" [rowDefinition]="rowDefinition" />
  </td>
</ng-template>
