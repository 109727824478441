import { Pipe, PipeTransform } from '@angular/core';
import { standardizeUrlForHref } from '@shared/utils';

@Pipe({
  name: 'standardizeUrlForHref',
  pure: true,
  standalone: true,
})
export class StandardizeUrlForHrefPipe implements PipeTransform {
  transform(url: string): string {
    return standardizeUrlForHref(url);
  }
}
