import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { Params, RouterModule } from '@angular/router';
import { IconComponent } from '@shared-lib/components/icon/icon.component';
import { StandardizeUrlForHrefPipe } from '@shared/pipes/standardize-url-for-href/standardize-url-for-href.pipe';

export enum LinkTextSize {
  EXTRA_SMALL = 'extra-small',
  SMALL = 'small',
  LARGE = 'large',
  EXTRA_LARGE = 'extra-large',
}

@Component({
  selector: 'ap-link',
  templateUrl: './link.component.html',
  styleUrls: ['./link.component.scss'],
  imports: [CommonModule, RouterModule, StandardizeUrlForHrefPipe, IconComponent],
})
export class LinkComponent {
  @Input() label: string;
  @Input() routerLink: string[] | string;
  @Input() queryParams: Params;
  @Input() textClass: 'extra-small' | 'small' | 'large' | 'extra-large' = LinkTextSize.EXTRA_SMALL;
  @Input() showIcon = false;
  @Input() externalLink: string;
}
