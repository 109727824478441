// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// source: shared/api/common/ENUM_MortgageCaseClosedReason.proto

export enum ENUM_MortgageCaseClosedReason {
  /** MORTGAGE_CASE_CLOSED_REASON_UNSPECIFIED - ------------------------------------------ -- ==== 1 391x ---> UNSPECIFIED */
  MORTGAGE_CASE_CLOSED_REASON_UNSPECIFIED = 'MORTGAGE_CASE_CLOSED_REASON_UNSPECIFIED',
  /** MORTGAGE_CASE_CLOSED_REASON_COMPLETED - ------------------------------------------ 6; ==== 3 723x ---> COMPLETED */
  MORTGAGE_CASE_CLOSED_REASON_COMPLETED = 'MORTGAGE_CASE_CLOSED_REASON_COMPLETED',
  /** MORTGAGE_CASE_CLOSED_REASON_CANCELLED_LOST - ------------------------------------- 1; ======= 39x ---> */
  MORTGAGE_CASE_CLOSED_REASON_CANCELLED_LOST = 'MORTGAGE_CASE_CLOSED_REASON_CANCELLED_LOST',
  /** MORTGAGE_CASE_CLOSED_REASON_CANCELLED_MORTGAGE_REPAID - -------------------------- 2; ====== 769x ---> MORTGAGE_CLOSED */
  MORTGAGE_CASE_CLOSED_REASON_CANCELLED_MORTGAGE_REPAID = 'MORTGAGE_CASE_CLOSED_REASON_CANCELLED_MORTGAGE_REPAID',
  /** MORTGAGE_CASE_CLOSED_REASON_CANCELLED_UNRESPONSIVE - ----------------------------- 3; ======= 32x ---> */
  MORTGAGE_CASE_CLOSED_REASON_CANCELLED_UNRESPONSIVE = 'MORTGAGE_CASE_CLOSED_REASON_CANCELLED_UNRESPONSIVE',
  /** MORTGAGE_CASE_CLOSED_REASON_CANCELLED_PROPERTY_SOLD - ---------------------------- 4; ==== 2 528x ---> MORTGAGE_CLOSED */
  MORTGAGE_CASE_CLOSED_REASON_CANCELLED_PROPERTY_SOLD = 'MORTGAGE_CASE_CLOSED_REASON_CANCELLED_PROPERTY_SOLD',
  /** MORTGAGE_CASE_CLOSED_REASON_CANCELLED_OTHER - ------------------------------------ 5; === 58 421x ---> (from closed_reason_other) */
  MORTGAGE_CASE_CLOSED_REASON_CANCELLED_OTHER = 'MORTGAGE_CASE_CLOSED_REASON_CANCELLED_OTHER',
  /** MORTGAGE_CASE_CLOSED_REASON_CANCELLED_STAYING_ON_EXISTING_DEAL - ----------------- 8; ======== 0x ---> - */
  MORTGAGE_CASE_CLOSED_REASON_CANCELLED_STAYING_ON_EXISTING_DEAL = 'MORTGAGE_CASE_CLOSED_REASON_CANCELLED_STAYING_ON_EXISTING_DEAL',
  /** MORTGAGE_CASE_CLOSED_REASON_CANCELLED_UNABLE_TO_PLACE - -------------------------- 9; ======== 0x ---> - */
  MORTGAGE_CASE_CLOSED_REASON_CANCELLED_UNABLE_TO_PLACE = 'MORTGAGE_CASE_CLOSED_REASON_CANCELLED_UNABLE_TO_PLACE',
  /** MORTGAGE_CASE_CLOSED_REASON_CANCELLED_LOST_TRANSFERRED_WITH_EXISTING_LENDER - --- 10; ======== 0x ---> - */
  MORTGAGE_CASE_CLOSED_REASON_CANCELLED_LOST_TRANSFERRED_WITH_EXISTING_LENDER = 'MORTGAGE_CASE_CLOSED_REASON_CANCELLED_LOST_TRANSFERRED_WITH_EXISTING_LENDER',
  /** MORTGAGE_CASE_CLOSED_REASON_CANCELLED_LOST_DIRECT_TO_LENDER - ------------------- 11; ======== 0x ---> - */
  MORTGAGE_CASE_CLOSED_REASON_CANCELLED_LOST_DIRECT_TO_LENDER = 'MORTGAGE_CASE_CLOSED_REASON_CANCELLED_LOST_DIRECT_TO_LENDER',
  /** MORTGAGE_CASE_CLOSED_REASON_CANCELLED_LOST_ANOTHER_BROKER - --------------------- 12; ======== 0x ---> - */
  MORTGAGE_CASE_CLOSED_REASON_CANCELLED_LOST_ANOTHER_BROKER = 'MORTGAGE_CASE_CLOSED_REASON_CANCELLED_LOST_ANOTHER_BROKER',
  /** MORTGAGE_CASE_CLOSED_REASON_CANCELLED_BY_CLIENT - ------------------------------- 13; ======== ?x ---> */
  MORTGAGE_CASE_CLOSED_REASON_CANCELLED_BY_CLIENT = 'MORTGAGE_CASE_CLOSED_REASON_CANCELLED_BY_CLIENT',
  /** MORTGAGE_CASE_CLOSED_REASON_CANCELLED_NOT_TAKEN_UP - ---------------------------- -- ======== ?x ---> */
  MORTGAGE_CASE_CLOSED_REASON_CANCELLED_NOT_TAKEN_UP = 'MORTGAGE_CASE_CLOSED_REASON_CANCELLED_NOT_TAKEN_UP',
  /** MORTGAGE_CASE_CLOSED_REASON_CANCELLED_NOT_PROCEEDING_WITH - --------------------- -- ======== ?x ---> */
  MORTGAGE_CASE_CLOSED_REASON_CANCELLED_NOT_PROCEEDING_WITH = 'MORTGAGE_CASE_CLOSED_REASON_CANCELLED_NOT_PROCEEDING_WITH',
  /** MORTGAGE_CASE_CLOSED_REASON_DEAL_EXPIRED - --------------------------------------- 7; === 15 076x ---> EXPIRED */
  MORTGAGE_CASE_CLOSED_REASON_DEAL_EXPIRED = 'MORTGAGE_CASE_CLOSED_REASON_DEAL_EXPIRED',
  UNRECOGNIZED = 'UNRECOGNIZED',
}
